<template>
  <div>
   Why use wakaru.org
  </div>
</template>

<script>
export default {
  name: 'WhyUse',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

</style>
